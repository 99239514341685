.jobsearch {
    &__top {
        margin-bottom: $spacer-12;
        @include md {
            margin-bottom: $spacer-24;
        }
        .container {
            padding: 0;
        }
    }
    &__results-container {
        scroll-margin-top: $spacer-12;
    }
    &__results {
        display: flex;
        flex-direction: column;
        gap: $spacer-6;
    }
    &__result {
        padding: $spacer-6;
        border-radius: $border-radius-lg;
        border: 1px solid $color-grey-20;
        transition: $transition-default;
        &:hover {
            @include elevation-closest;
            outline: none;
        }
    }
    &__result-title {
        margin-bottom: $spacer-4 !important;
        display: flex;
        align-items: flex-start;
        gap: $spacer-3;
        flex-direction: column-reverse;
        @include md {
            flex-direction: row;
            align-items: center;
        }
    }
    &__result-details {
        display: flex;
        gap: $spacer-2 $spacer-5;
        align-items: center;
        color: $color-grey-60;
        flex-wrap: wrap;
    }
    &__result-detail {
        display: flex;
        gap: 0.375rem;
        align-items: center;
        white-space: nowrap;
    }
    &__remote-badge {
        color: $color-ocean;
        font-size: $font-size-small;
        padding: $spacer-1 $spacer-2;
        background: map-get($backgrounds, 'denim-lightest');
        border: 1px solid $color-ocean;
        border-radius: $border-radius-sm;
        line-height: normal;
    }
    &__search-form {
        .form {
            margin-bottom: $spacer-3;
            @include md {
                display: flex;
                align-items: flex-end;
                gap: $spacer-7;
                margin-bottom: 0;
            }
        }
        .form__fields {
            display: flex;
            flex-direction: column;
            gap: $spacer-6;
            flex: 1;
            @include md {
                flex-direction: row;
                gap: $spacer-7;
                margin-bottom: 0;
            }
        }
        .form__field {
            flex: 1;
            @include md {
                max-width: 457px;
            }
        }
        .btn {
            height: 48px;
        }
    }
    &__filter-form {
        scroll-margin-top: $spacer-12;
        .form__fields {
            gap: $spacer-5;
            margin-bottom: $spacer-5;
        }

        hr {
            border: none;
            border-top: 1px solid $color-grey-20;
            margin: 0 !important;
        }
        display: grid;
        row-gap: $spacer-5;
        padding: $spacer-12 $spacer-4;
        @include md {
            border-radius: $border-radius-lg;
            border: 1px solid $color-grey-20;
            padding: $spacer-8;
        }
    }
    &__filter-title {
        line-height: normal;
    }
    &__mobile-filter-btn {
        display: block;
        height: 48px;
        @include md {
            display: none;
        }
    }
    &__filter-modal {
        background: $color-white;
        overflow-y: scroll;
        max-height: none;
        .modal__content {
            height: 100%;
            margin: 0;
        }
        .modal__body {
            padding: 0;
            max-height: none;
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
    &__filter-modal-title {
        color: $color-black;
        font-size: $font-size-body;
        padding: $spacer-4;
        text-align: center;
        border-bottom: $color-grey-20 1px solid;
        font-weight: $font-weight-bold;
        position: relative;
    }
    &__filter-modal-x-btn {
        @include resetButton;
        @include focus;
        position: absolute;
        right: $spacer-4;
    }
    &__filter-modal-x {
        font-size: 22px;
        color: $color-grey-80;
    }
    &__filter-buttons {
        border-top: $color-grey-20 solid 1px;
        display: flex;
        gap: $spacer-3;
        padding-top: $spacer-4;
    }
    &__detail-sidebar {
        padding: $spacer-8;
        border-radius: $border-radius-lg;
        border: 1px solid $color-grey-20;
        hr {
            border: none;
            border-top: 1px solid $color-grey-20;
            margin: 0;
        }
        p,
        div {
            color: $color-grey-60;
        }
        display: none;
        @include md {
            display: grid;
            gap: $spacer-5;
        }
    }
    &__detail-title {
        margin-bottom: $spacer-2;
    }
    &__detail-more {
        @include resetButton;
        color: $color-crimson;
    }
    &__modal-btns {
        display: flex;
        column-gap: 1rem;
        border-top: $color-grey-20 1px solid;
        padding: $spacer-4;
        margin-top: auto;
        @include md {
            margin-top: 0;
            display: none;
        }
    }
    &__cta {
        margin-top: 3.5rem;
    }
    &__mobile-details {
        display: grid;
        gap: $spacer-6;
        margin-bottom: $spacer-12;
        @include md {
            display: none;
        }
    }
    &__mobile-apply {
        display: block;
        position: sticky;
        bottom: 3.75rem;
        border-radius: 0;
        z-index: 10;
        @include md {
            display: none;
        }
    }
}
