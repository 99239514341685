.list {
    padding-left: $spacer-4 !important;
    text-align: left;
    &:not(:first-child) {
        margin-top: $spacer-8;
    }
    &__item {
        font-size: var(--font-size-body);
        line-height: $spacer-7;
        color: $color-grey-80;
        list-style-type: none;
        display: flex;
        align-items: flex-start;
        margin-left: 0 !important;
        @include nlc {
            margin-bottom: $spacer-4 !important;
        }
    }
    &__icon {
        width: $spacer-7;
        height: $spacer-7;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacer-3;
        color: $color-white;
        border-radius: 50%;
        &--media {
            width: auto;
            height: auto;
        }
    }
    &__separator {
        font-weight: bold;
        font-style: italic;
        margin-top: $spacer-2;
    }
    &__media-icon {
        line-height: 4rem !important;
    }
    &--checkmark & {
        &__icon {
            background: $color-ocean;
        }
    }
    &--number & {
        &__icon {
            background: $color-blue;
        }
    }
    &--cross & {
        &__icon {
            background: $color-ruby;
        }
    }
    &--split {
        @include md {
            column-count: 2;
        }
    }
}
